import FeatureFlagsProvider from 'context/FeatureFlagsProvider';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

import { GetQuestionnaire } from 'generated/graphql';

interface SessionInfo {
  id: string;
  access: string;
  sessionFormType: string;
}
export interface AppContextProps {
  currentPageId: string;
  setCurrentPageId: (pageId: string) => void;
  sessionInfo: SessionInfo;
  questionnaireName: string;
  setQuestionnaireName: (name: string) => void;
  navigation?: GetQuestionnaire.navigation | null;
  setNavigation?: (navigation: GetQuestionnaire.navigation) => void;
  isSuccessfullySubmitted: boolean;
  setIsSuccessfullySubmitted: (isSuccessfullySubmitted: boolean) => void;
}

export const AppContext = createContext<AppContextProps>({
  currentPageId: '',
  setCurrentPageId: () => '',
  questionnaireName: '',
  setQuestionnaireName: () => '',
  sessionInfo: { id: '', access: '', sessionFormType: '' },
  navigation: null,
  setNavigation: () => '',
  isSuccessfullySubmitted: false,
  setIsSuccessfullySubmitted: () => '',
});

export const AppContextProvider = ({
  children,
  sessionInfo,
}: {
  children: ReactNode;
  sessionInfo: SessionInfo;
}) => {
  const { push, query } = useRouter();
  const [currentPageId, setCurrentPageId] = useState(query.page as string);
  const [questionnaireName, setQuestionnaireName] = useState('');
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [navigation, setNavigation] =
    useState<GetQuestionnaire.navigation | null>(null);

  const onSetCurrentPageId = useCallback(
    (pageId: string) => {
      setCurrentPageId(pageId);
      push({ query: { ...query, page: pageId } }, undefined, {
        shallow: true,
      });
    },
    [push, query, setCurrentPageId],
  );

  return (
    <FeatureFlagsProvider>
      <AppContext.Provider
        value={{
          currentPageId,
          setCurrentPageId: onSetCurrentPageId,
          sessionInfo,
          questionnaireName,
          setQuestionnaireName,
          isSuccessfullySubmitted,
          setIsSuccessfullySubmitted,
          navigation,
          setNavigation,
        }}
      >
        {children}
      </AppContext.Provider>
    </FeatureFlagsProvider>
  );
};

export const useAppContext = (): AppContextProps => useContext(AppContext);
