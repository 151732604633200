import { SplitFactory } from '@splitsoftware/splitio';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useLocalStorage } from '@anchorage/hooks';

import {
  type AllFeatureFlags,
  type FeatureFlagContextProps,
  type FeatureFlagProviderProps,
  type ProviderOptions,
} from './types';

export enum DEFAULT_VALUES {
  ON = 'on',
  OFF = 'off',
  CONTROL = 'control',
}

// ----------------------------------------------
// To test
//
// - Make sure the sdkClient state is only set after the SDK_READY event
// - Make sure the sdkClient is only destroyed when leaving the app (not the page)
// - Make sure we only download the requested flags
// - Make sure we the key in local storage includes options.project as identifier
// - Make sure local overrides are working, even when using the same feature in different projects
// - Make sure the local override value is updated when the value changes in local storage
//

const contextInitialValue: FeatureFlagContextProps = {
  useIsFeatureActive: (_) => [false, {}, false],
  useFlagValue: (_) => [DEFAULT_VALUES.OFF, {}],
  useAllFlags: (_) => ({}),
  flagsInLocalStorage: {},
  setFlagsInLocalStorage: () => {},
};

const FeatureFlagContext = createContext(contextInitialValue);
export const useFeatureFlagsContext = () => useContext(FeatureFlagContext);

const prepareSdkConfig = (
  options: ProviderOptions,
  defaults: AllFeatureFlags,
): SplitIO.IBrowserSettings => {
  const defaultConfigs = Object.keys(defaults).reduce((acc, flag) => {
    return Object.assign(acc, {
      [flag]: {
        treatment: defaults[flag]?.treatment,
        config: JSON.stringify(defaults[flag]?.config),
      },
    });
  }, {});

  return {
    core: {
      authorizationKey: options.apiKey,
      key: options.trafficKey,
      trafficType: options.trafficType,
    },
    // For testing purposes, if we provide 'localhost' as the API Key
    // we will the default values provided.
    // If we don't pass all the flags, isFeatureActive will return false
    // because the SDK will return 'control'.
    features: options.apiKey === 'localhost' ? defaultConfigs : undefined,
    sync: {
      // Only fetch the feature flags for which we have a default.
      // Avoids downloading all the splits.
      splitFilters: [
        {
          type: 'byName',
          values: Object.keys(defaultConfigs),
        },
      ],
    },
    storage: {
      prefix: options.project,
    },
    debug: options.debug || false,
  };
};

export const FeatureFlagProvider = ({
  baseAttributes,
  defaults,
  options: providerOptions,
  children,
}: FeatureFlagProviderProps): JSX.Element => {
  const [orgClient, setOrgClient] = useState<SplitIO.IClient>();
  const [flagsInLocalStorage, setFlagsInLocalStorage] =
    useLocalStorage<AllFeatureFlags>(
      `feature_flags_${providerOptions.project}`,
      {},
    );

  const sdkConfig = useMemo(
    () => prepareSdkConfig(providerOptions, defaults),
    [providerOptions, defaults],
  );

  useEffect(() => {
    async function initSplitClient() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Window does not contain split
      window.split = (window.split ||
        SplitFactory(sdkConfig)) as SplitIO.IBrowserSDK;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Window does not contain splitClient
      window.splitClient = (window.splitClient ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Window does not contain splitClient
        (window.split as SplitIO.IBrowserSDK).client(
          baseAttributes.orgId as string,
        )) as SplitIO.IClient;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Window does not contain splitClient
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      await window.splitClient.ready();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Window does not contain splitClient
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setOrgClient(window.splitClient);
    }

    void initSplitClient();
  }, [baseAttributes.orgId, sdkConfig]);

  if (!orgClient) {
    console.warn(
      'Feature Flag client not ready yet. Default values will be returned.',
    );
  }
  /**
   * Returns whether a feature flag is enabled of not.
   * @function useIsFeatureActive
   * @param {Parameters<FeatureFlagContextProps['useIsFeatureActive']>[0]} feature The name of the feature flag
   * @param {Parameters<FeatureFlagContextProps['useIsFeatureActive']>[1]} options options for the query
   * @returns {ReturnType<FeatureFlagContextProps['useIsFeatureActive']>} - An array with `true` if the flag is enabled, `false` otherwise
   * in the first position and the associated config in the second position and a boolean indicating if the SDK is ready in the third position
   */
  const useIsFeatureActive: FeatureFlagContextProps['useIsFeatureActive'] = (
    feature,
    options = { disableLocalOverrides: false },
  ) => {
    const [flagsInLocalStorage] = useLocalStorage<AllFeatureFlags>(
      `feature_flags_${providerOptions.project}`,
      {},
    );

    const localValue =
      flagsInLocalStorage[feature]?.treatment === (DEFAULT_VALUES.ON as string);
    const localConfig = flagsInLocalStorage[feature]?.config ?? {};
    const defaultValue =
      defaults[feature]?.treatment === (DEFAULT_VALUES.ON as string);
    const defaultConfig = defaults[feature]?.config ?? {};

    let isReady = false;

    if (!orgClient) {
      return options.disableLocalOverrides
        ? [defaultValue, defaultConfig, isReady]
        : [localValue, localConfig, isReady];
    }

    const treatmentWithConfig = orgClient.getTreatmentWithConfig(feature, {
      ...baseAttributes,
      ...options.attributes,
    });

    const value = treatmentWithConfig.treatment;
    const config = JSON.parse(treatmentWithConfig.config || '{}') as Record<
      string,
      string
    >;

    isReady = true;

    if (!options.disableLocalOverrides && flagsInLocalStorage[feature]) {
      return [localValue, localConfig, isReady];
    }

    switch (value) {
      case DEFAULT_VALUES.ON as string:
        return [true, config, isReady];
      case DEFAULT_VALUES.OFF as string:
        return [false, config, isReady];
      case DEFAULT_VALUES.CONTROL as string:
        return [false, config, isReady];
      default:
        return [defaultValue, config, isReady];
    }
  };

  /**
   * Returns the value for a given feature flag, falling back to the
   * default value if it's treatment is 'control'.
   * @function useFlagValue
   * @param {Parameters<FeatureFlagContextProps['useFlagValue']>[0]} feature - The name of the feature flag.
   * @param {Parameters<FeatureFlagContextProps['useIsFeatureActive']>[1]} options options for the query
   * @returns {ReturnType<FeatureFlagContextProps['useFlagValue']>} - An array with flag value in the first position
   * and the associated config in the second position
   */
  const useFlagValue: FeatureFlagContextProps['useFlagValue'] = (
    feature,
    options = { disableLocalOverrides: false },
  ) => {
    const [flagsInLocalStorage] = useLocalStorage<AllFeatureFlags>(
      `feature_flags_${providerOptions.project}`,
      {},
    );

    const localValue =
      flagsInLocalStorage[feature]?.treatment ?? DEFAULT_VALUES.OFF;
    const localConfig = flagsInLocalStorage[feature]?.config ?? {};
    const defaultValue = defaults[feature]?.treatment ?? DEFAULT_VALUES.OFF;
    const defaultConfig = defaults[feature]?.config ?? {};

    if (!orgClient) {
      return options.disableLocalOverrides
        ? [defaultValue, defaultConfig]
        : [localValue, localConfig];
    }

    const treatmentWithConfig = orgClient.getTreatmentWithConfig(feature, {
      ...baseAttributes,
      ...options.attributes,
    });

    const value = treatmentWithConfig.treatment;
    const config = JSON.parse(treatmentWithConfig.config || '{}') as Record<
      string,
      string
    >;

    if (!options.disableLocalOverrides && localValue) {
      return [localValue, localConfig];
    }

    switch (value) {
      case DEFAULT_VALUES.CONTROL as string:
        return [defaultValue, config];
      default:
        return [value, config];
    }
  };

  /**
   * Returns the values for all feature flags, falling back to the default value
   * of a flag if it's treatment is 'control'.
   * @function useAllFlags
   * @param {Parameters<FeatureFlagContextProps['useAllFlags']>[0]} parameters The method parameters
   * @param {Parameters<FeatureFlagContextProps['useIsFeatureActive']>[1]} options options for the query
   * @returns {ReturnType<FeatureFlagContextProps['useAllFlags']>} A map with all the flags and the correspondent values.
   */
  const useAllFlags: FeatureFlagContextProps['useAllFlags'] = (
    options = { disableLocalOverrides: false },
  ) => {
    const [flagsInLocalStorage] = useLocalStorage<AllFeatureFlags>(
      `feature_flags_${providerOptions.project}`,
      {},
    );

    if (!orgClient) {
      return options?.disableLocalOverrides ? defaults : flagsInLocalStorage;
    }

    const flags = Object.keys(defaults);
    const treatmentsWithConfig = orgClient.getTreatmentsWithConfig(flags, {
      ...baseAttributes,
      ...options.attributes,
    });

    const flagsWithTreatment = flags.reduce((acc, flag) => {
      const flagValue = treatmentsWithConfig[flag]?.treatment;
      const defaultValue = defaults[flag]?.treatment;
      const flagConfig = treatmentsWithConfig[flag]?.config;
      const defaultConfig = defaults[flag]?.config;
      return Object.assign(acc, {
        [flag]: {
          treatment:
            flagValue === (DEFAULT_VALUES.CONTROL as string)
              ? defaultValue
              : flagValue,
          config:
            flagValue === (DEFAULT_VALUES.CONTROL as string)
              ? defaultConfig
              : flagConfig,
        },
      });
    }, {});

    return {
      ...flagsWithTreatment,
      ...(options.disableLocalOverrides ? {} : flagsInLocalStorage),
    };
  };

  const providerValue = {
    useIsFeatureActive,
    useFlagValue,
    useAllFlags,
    flagsInLocalStorage,
    setFlagsInLocalStorage,
  };

  return (
    <FeatureFlagContext.Provider value={providerValue}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
